import appConfig from "@/app.config.json";
import {
  HTTPResponse,
  requestHeaders,
  SimpleStringApiResponse,
  timestampFrom,
} from "@/services";
import { log } from "@/stores/LogStore";
import { fromException } from "@/utils";
import { TimesheetEntryViewModel } from "@/models/viewmodels/TimesheetEntryViewModel";
import axios, { AxiosRequestConfig } from "axios";
import { Timer } from "timer-node";

/**
 * Posts a new time sheet entry.@/model/viewmodel/TimesheetEntryViewModel
 *
 * @param entry - The new timesheet entry.
 * @returns The timesheet entry with its server assigned ID.
 * @throws Any AxiosError thrown from the call after logging it.
 */
export const sendLogs = async (
  entry: any,username:any
): Promise<HTTPResponse<any>> => {
  const url = `${appConfig.serviceUrlRoot}/UploadLogs`;
  const timer = new Timer({ label: `POST ${url}` }).start();
  const config: AxiosRequestConfig<any> = {
    method: "post",
    url,
    params: {
    },
    headers: requestHeaders(),
    data: {
      log_val:entry,
      user_name:username,
    //   start_time: toTimesheetFormat(entry.start_time),
    //   end_time: toTimesheetFormat(entry.end_time ?? null),
      tz_name: "UTC", // always
    },
  };
  // console.log(config)
  try {
    const response = await axios.request<any>(config);
    return {
      timestamp: timestampFrom(response),
      status: response.status,
      statusText: response.statusText,
      payload: { ...entry, id: response.data.data.ts_id },
    };
  } catch (e: any) {


    return {
      timestamp: "",
      status: e.response.status ?? 0,
      statusText: e.response.statusText ?? `${e}`,
    };

    // console.log(e)
    // log().error(
    //   timer.getLabel(),
    //   `exception`,
    //   { exception: fromException(e), config },
    //   timer.stop().ms()
    // );
    throw e;
  }
};

/**
 * Puts an update to a time sheet entry.
 *
 * @param entry - The revised timesheet entry.
 * @returns The timesheet entry with its server assigned ID.
 * @throws Any AxiosError thrown from the call after logging it.
 */
// export const modifyTimesheetEntry = async (
//   entry: TimesheetEntryViewModel
// ): Promise<HTTPResponse<TimesheetEntryViewModel>> => {
//   const url = `${appConfig.serviceUrlRoot}/time-sheet`;
//   const timer = new Timer({ label: `PUT ${url}` }).start();

//   const config: AxiosRequestConfig<TimesheetEntryRequest> = {
//     method: "put",
//     url,
//     params: {
//       woid: entry.wo_id,
//       ts_id: entry.id,
//     },
//     headers: requestHeaders(),
//     data: {
//       start_time: toTimesheetFormat(entry.start_time),
//       end_time: toTimesheetFormat(entry.end_time ?? null),
//       tz_name: "UTC", // always
//     },
//   };
//   // console.log(config)
//   try {
//     const response = await axios.request<SimpleStringApiResponse>(config);
//     return {
//       timestamp: timestampFrom(response),
//       status: response.status,
//       statusText: response.statusText,
//       payload: { ...entry },
//     };
//   } catch (e: any) {
//     log().error(
//       timer.getLabel(),
//       `exception`,
//       { exception: fromException(e), config },
//       timer.stop().ms()
//     );
//     throw e;
//     // return {
//     //   timestamp: "",
//     //   status: e.response.status ?? 0,
//     //   statusText: e.response.statusText ?? `${e}`,
//     // };
//   }
// };

// /**
//  * Deletes a time sheet entry using its ID.
//  *
//  * @param entry - The timesheet entry.
//  * @returns The timesheet entry that has just been deleted.
//  * @throws Any AxiosError thrown from the call after logging it.
//  */
// export const deleteTimesheetEntry = async (
//   entry: TimesheetEntryViewModel
// ): Promise<HTTPResponse<TimesheetEntryViewModel>> => {
//   if (!entry.id) throw `cannot DELETE TimesheetEntry ${entry.uuid} with no ID`;
//   const url = `${appConfig.serviceUrlRoot}/time-sheet`;
//   const timer = new Timer({ label: `DELETE ${url}` }).start();

//   const config: AxiosRequestConfig = {
//     method: "delete",
//     url,
//     params: {
//       woid: entry.wo_id,
//       ts_id: entry.id,
//     },
//     headers: requestHeaders(),
//   };
//   try {
//     const response = await axios.request<SimpleStringApiResponse>(config);
//     return {
//       timestamp: timestampFrom(response),
//       status: response.status,
//       statusText: response.statusText,
//       payload: { ...entry },
//     };
//   } catch (e: any) {
//     log().error(
//       timer.getLabel(),
//       `exception`,
//       { exception: fromException(e), config },
//       timer.stop().ms()
//     );
//     throw e;
//     // return {
//     //   timestamp: "",
//     //   status: e.response.status ?? 0,
//     //   statusText: e.response.statusText ?? `${e}`,
//     // };
//   }
// };

// interface TimesheetEntryRequest {
//   start_time: string | null; // never null
//   end_time: string | null;
//   tz_name: string; // or null
// }

// interface POST_TimesheetEntryResponse {
//   status_code: number;
//   server_timestamp: string;
//   data: { ts_id: number };
// }

// /**
//  * Converts the format of a timestamp from standard ISO to the hyphenless format
//  * without milliseconds or trailing 'Z' required for timesheet API calls. The presence
//  * of these extraneous characters, or missing digits for seconds may result in a 200 Success
//  * response, having recorded the present instant instead of the desired timestamp.
//  *
//  * @param since - The UTC timestamp in ISO format.
//  * @returns The timestamp without milliseconds, hyphens, colons, periods, or 'Z';
//  * or null.
//  */
// function toTimesheetFormat(since: string | null): string | null {
//   if (since) since = since.replace(/\..*$/, "");
//   if (since) since = since.replaceAll(/[Z:-]/g, "");
//   if (since) {
//     while (since.length < 15) {
//       since += "0";
//     }
//   }
//   return since;
// }



export const errorCode = async (
): Promise<HTTPResponse<any>> => {
  const url = `${appConfig.serviceUrlRoot}/notfound`;
  const timer = new Timer({ label: `POST ${url}` }).start();
  const config: AxiosRequestConfig<any> = {
    method: "get",
    url,
    params: {
    },
    headers: requestHeaders(),
    data: {
    },
  };
  // console.log(config)
  try {
    const response = await axios.request<any>(config);
    return {
      timestamp: timestampFrom(response),
      status: response.status,
      statusText: response.statusText,
      // payload: { ...entry, id: response.data.data.ts_id },
    };
  } catch (e: any) {


    return {
      timestamp: "",
      status: e.response.status ?? 0,
      statusText: e.response.statusText ?? `${e}`,
    };

    // console.log(e)
    // log().error(
    //   timer.getLabel(),
    //   `exception`,
    //   { exception: fromException(e), config },
    //   timer.stop().ms()
    // );
    throw e;
  }
};
